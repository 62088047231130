import plugin from 'tailwindcss/plugin'
import defaultTheme from 'tailwindcss/defaultTheme'

export default {
  content: [
    './components/**/*.{js,vue,ts,jsx,tsx}',
    './layouts/**/*.{vue,tsx,jsx}',
    './pages/**/*.{vue,tsx,jsx}',
    './*.{vue,tsx,jsx}',
    './plugins/**/*.{js,ts}',
    './nuxt.config.{js,ts}'
  ],

  safelist: [
    'before:w-[1.5rem]',
    'before:h-[1.5rem]',
    'before:w-[1.25rem]',
    'before:h-[1.25rem]',
    'min-h-[17.0625rem]',
    'max-h-[17.0625rem]'
  ],

  plugins: [
    plugin(function ({
      addVariant,
      matchUtilities,
      theme
    }) {
      addVariant('not-last', '&:not(:last-child)')

      addVariant('last', '&:last-child')

      matchUtilities(
        {
          'exact-w': value => ({
            'min-width': value,
            width: value,
            'max-width': value
          })
        },
        { values: theme('width') }
      )

      matchUtilities(
        {
          'exact-h': value => ({
            'min-height': value,
            height: value,
            'max-height': value
          })
        },
        { values: theme('height') }
      )

      matchUtilities(
        {
          wh: value => ({
            width: value,
            height: value
          })
        },
        { values: theme('width') }
      )

      matchUtilities(
        {
          'exact-wh': value => ({
            'min-width': value,
            'max-width': value,
            width: value,
            'min-height': value,
            'max-height': value,
            height: value
          })
        },
        { values: theme('width') }
      )
    })
  ],

  theme: {
    screens: {
      xxxs: '320px',
      'xs': '375px',
      ...defaultTheme.screens
    },

    extend: {
      colors: {
        primary: '#0ac16a',
        dark: '#193560',
        black: '#010812',
        danger: '#ea5455',
        success: '#28c76f',
        'transparent-dark': 'rgba(25,53,96,.75)',
        alert: {
          error: {
            stroke: '#c50e0e',
            fill: '#ffe1e1'
          },
          info: {
            stroke: '#74ceff',
            fill: '#eff9ff'
          },
          warning: {
            stroke: '#ffe847',
            fill: '#fffce7'
          },
          success: {
            stroke: '#BDD883',
            fill: '#f2fff9'
          }
        },
        gray: {
          5: '#f8f8f8', // form field bg:dark
          10: '#f9f9f9', // light bg1
          20: '#E2E4E6',
          30: '#bebebe', // grey optional
          40: '#aaaaaa', // inactive
          80: '#666766', // disclaimer

          25: '#f5f8fa',
          75: '#86878d', // label text:default
          125: '#7f7f7f',
          200: '#f5f7f6',
          250: '#C0C0C0',
          300: '#F2F2F2',
          350: '#f0f0f0', // light bg2
          400: '#d2d2d2', // outlines divider
          500: '#828282',
          600: '#DDDDDD'
        },
        green: {
          5: '#f3fcf8',
          10: '#e7f9f0',
          20: '#cef3e1',
          30: '#b6ecd2',
          40: '#9de6c3',
          50: '#84e0b4',
          60: '#6cdaa6',
          70: '#54d497',
          80: '#3bcd88',
          90: '#23c779',
          100: '#2CD18F'
        },
        blue: {
          5: '#f4f5f7', // #f5f6f7
          10: '#e8eBef', // '#e7eaee',
          15: '#F4F7FC',
          20: '#d1d7df',
          30: '#bac2cf',
          40: '#a3aebf',
          50: '#8c9aaf',
          60: '#7586a0',
          70: '#5e7290',
          80: '#475d80',
          90: '#304970',
          500: '#1183C5', // blue crayola
          600: '#1a8b9d',
          800: '#362092', // blue picotee
          900: '#0b1d39', // footer bg
          fb: '#395185',
          'linked-in': '#0a66c2'
        },
        zinc: {
          400: '#BEBEBE'
        },
        pink: {
          600: '#e83f6f'
        },
        neutral: {
          50: '#fafafa',
          75: '#fafbfc',
          100: '#f9f9f9',
          400: '#86878d'
        },
        sky: {
          700: '#435f7d'
        },
        yellow: {
          200: '#fdba12',
          600: '#ffb10a'
        },
        orange: {
          650: '#ED6A5A',
          700: '#FF8642',
          750: '#FF86421A'
        },
        cyan: {
          600: '#01baef'
        },
        purple: {
          70: '#552692', // purple
          700: '#5526920D', // purple
          80: '#381960', // promo bg
          90: '#180c33' // dark purple
        },
        brown: {
          50: '#9A5533'
        }
      },
      backgroundPosition: {
        'left-1': '0.125rem 0.35rem'
      },
      spacing: {
        '1.5': '0.45rem',
        '7.5': '1.875rem',
        '15': '3.75rem',
        '17': '4.5625rem',
        '18': '4.6875rem'
      },
      boxShadow: {
        'md-invert': '0 -0.25rem 1rem rgba(0, 0, 0, 0.12)',
        md: '0 0.5rem 1rem rgba(0, 0, 0, 0.12)',
        lg: '0 0 0.4375rem rgba(14, 31, 53, 0.12)',
        xl: '0 0.75rem 3.125rem 0 rgba(25, 53, 96, 0.3)',
        inner: 'inset 0 0.25rem 0.5rem rgba(0, 0, 0, 0.16)',
        default: '0 0.3125rem 3.125rem 0.3125rem rgba(25, 53, 96, 0.06)',
        bordered: '0 .25rem .5rem rgba(14,31,53,.16)',

        panel: '0 0.3125rem 2.5rem rgba(0, 0, 0, 0.12)',
        onclick: 'inset 0 0.25rem 0.5rem rgba(0, 0, 0, 0.16)',
        hover: '0 3.5rem 10rem 0.3125rem rgba(0, 0, 0, 0.05)',
        header: '0 0.125rem 0.125rem rgba(14, 31, 53, 0.12)',

        'floating-sm': '0 0.1875rem 0.5rem rgba(25, 53, 96, 0.32)',
        'floating-lg': '0 3.125rem 6.25rem rgba(25, 53, 96, 0.2)',

        'right-only': '0.3125rem 0 0.875rem -0.25rem rgb(0 0 0 / 10%)',
        'bottom-only': '0 0.6875rem 0.625rem -0.5rem rgb(0 0 0 / 10%)',

        'sm-1': '0 0 0.125rem 0 rgba(14, 31, 53, 0.12)',
        'sm-2': '0 0.125rem 0.25rem rgba(14, 31, 53, 0.12)',
        'sm-3': '0 0.1875rem 0.4375rem rgba(14, 31, 53, 0.12)',
        'sm-4': '0 0.25rem 0.5rem rgba(14, 31, 53, 0.16)',
        'sm-5': '0 0.4375rem 0.75rem rgba(14, 31, 53, 0.14)',

        'md-1': '0 0.375rem 1.25rem rgba(0, 0, 0, 0.12)',
        'md-2': '0 0.875rem 1.5rem rgba(14, 31, 53, 0.16)',
        'md-3': '0 1rem 1.75rem -0.0625rem rgba(14, 31, 53, 0.18)',
        'md-4': '0 1.75rem 2.5rem -0.0625rem rgba(14, 31, 53, 0.2)',

        'lg-1': '0 2rem 3rem -0.125rem rgba(14, 31, 53, 0.2)',
        'lg-2': '0 2.25rem 3.5rem -0.25rem rgba(14, 31, 53, 0.28)',
        'lg-3': '0 2.5rem 4rem -0.375rem rgba(14, 31, 53, 0.36)',
        'lg-4': '0 3rem 5.25rem -0.5rem rgba(14, 31, 53, 0.48)'
      },
      dropShadow: {
        'sm-1': '0 0 0.125rem rgba(14, 31, 53, 0.12)',
        'sm-2': '0 0.125rem 0.25rem rgba(14, 31, 53, 0.12)',
        'sm-3': '0 0.1875rem 0.4375rem rgba(14, 31, 53, 0.12)',
        'sm-4': '0 0.25rem 0.5rem rgba(14, 31, 53, 0.16)',

        'md-1': '0 0.375rem 1.25rem rgba(0, 0, 0, 0.12)',
        'md-2': '0 0.875rem 1.5rem rgba(14, 31, 53, 0.16)',
        'md-3': '0 1rem 1.75rem -0.0625rem rgba(14, 31, 53, 0.18)',
        'md-4': '0 1.75rem 2.5rem -0.0625rem rgba(14, 31, 53, 0.2)',

        'lg-1': '0 2rem 3rem -0.125rem rgba(14, 31, 53, 0.2)',
        'lg-2': '0 2.25rem 3.5rem -0.25rem rgba(14, 31, 53, 0.28)',
        'lg-3': '0 2.5rem 4rem -0.375rem rgba(14, 31, 53, 0.36)',
        'lg-4': '0 3rem 5.25rem -0.5rem rgba(14, 31, 53, 0.48)'
      },
      borderWidth: {
        1: '1px',
        3: '3px',
        6: '6px'
      },
      maxWidth: {
        initial: 'initial',
        unset: 'unset',
        'nav-container': '58.5625rem',
        'wrapper-xs': '38rem',
        'wrapper-xs2': '40.875rem',
        'wrapper-sm': '46rem',
        'wrapper-md': '58rem',
        'wrapper-lg': '70rem', // default
        'wrapper-lg-px-4': '72rem',
        'wrapper-xl': '84rem',
        '1.5lg': '34rem',
        '1.5xl': '40rem',
        '2.5xl': '45.6875rem',
        '4.5xl': '62.725rem',
        '5.5xl': '68rem',
        '8xl': '90rem',
        '8xxl': '120rem',
        'content': '100rem'
      },
      width: {
        unset: 'unset',
        'wrapper-xs': '38rem',
        'wrapper-xs2': '40.875rem',
        'wrapper-sm': '46rem',
        'wrapper-md': '58.125rem',
        'wrapper-lg': '70rem', // default
        'wrapper-lg-px-4': '72rem',
        'wrapper-xl': '84rem',
        inherit: 'inherit',
        26: '6.4969rem',
        66: '16.3125rem',
        76: '19.75rem',
        100: '25.875rem',
        120: '47rem'
      },
      fontSize: {
        '2xxs': '0.5rem',
        '2xs': '0.625rem',
        '1.5xl': ['1.375rem', '1.875rem'],
        '2.5xl': ['1.75rem', '2.25rem'],
        '3.5xl': ['2rem', '2.5rem'],
        '4.5xl': ['2.5rem', '3rem'],
        '6.5xl': '4rem',
        '5xl': ['3rem', '3.5rem']
      },
      zIndex: {
        60: '60'
      },
      borderRadius: {
        '2md': '0.5rem',
        lg: '10px',
        '2.5xl': '20px'
      },
      minWidth: {
        unset: 'unset',
        initial: 'initial',
        inherit: 'inherit',
        200: '200px',
        70: '17.5rem',
        80: '20rem'
      },
      maxHeight: {
        initial: 'initial',
        unset: 'unset',
        inherit: 'inherit',
        44: '44px',
        214: '214px',
        300: '300px'
      },
      minHeight: {
        initial: 'initial',
        unset: 'unset',
        inherit: 'inherit'
      },
      height: {
        auto: 'auto',
        unset: 'unset',
        inherit: 'inherit',
        15: '60px',
        17: '73px',
        '4xl': '28.5625rem'
      },
      lineHeight: {
        6.5: '1.625rem',
        '12': '3rem'
      },
      screens: {
        'xs': '414px',
        'xxl': '2000px'
      },
      gridTemplateColumns: {
        'contentful-3column-pc': 'minmax(1rem,1fr) minmax(auto,19.3125rem) minmax(auto,40.875rem) minmax(auto,19.3125rem) minmax(1rem,1fr)',
        'contentful-3column-mb': 'minmax(1rem,1fr) minmax(auto, 19.3125rem) 1fr minmax(auto, 19.3125rem) minmax(1rem,1fr)',
        'contentful-sidebar-v2': 'minmax(1rem,1fr) minmax(auto, 46rem) minmax(auto,24rem) minmax(1rem,1fr)',
        'contentful-sidebar': 'minmax(1rem,1fr) minmax(auto,42.5375rem) minmax(auto,20.1875rem) minmax(1rem,1fr)',
        'contentful-sidebar-light': 'minmax(1rem,1fr) minmax(auto,53.25rem) minmax(auto,18.75rem) minmax(1rem,1fr)',
        'contentful-light': 'minmax(1rem,1fr) minmax(auto,32rem) minmax(auto,32rem) minmax(1rem,1fr)'
      }
    },

    fontFamily: {
      sans: ['Nunito Sans', 'MS Sans Serif', 'Helvetica', 'sans-serif']
    }
  }
}
