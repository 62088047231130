/*
 | ==================================================
 | This File is for front end constants for memberhub
 | ==================================================
 */
export default {
  /*
   * API Status codes
   */
  API_SUCCESS: 200,
  API_CREATED: 201,
  API_MULTIPLE_CHOICES: 300,
  API_BAD_REQUEST: 400,
  API_TOKEN_EXPIRED: 401,
  API_FORBIDDEN: 403,
  API_INVALID_TOKEN: 419,
  API_VALIDATION_ERROR: 422,
  API_MAINTENANCE: 503,
  API_PERMANENT_REDIRECT: 308,
  /*
   * Social auth constants
   */
  GOOGLE_AUTH: 1,
  FACEBOOK_AUTH: 2,
  LINKEDIN_AUTH: 3,
  APPLE_AUTH: 4,
  /*
   * Account settings sections
   */
  PERSONAL: 1,
  COMMUNICATION: 2,
  PASSWORD: 3,
  ENQUIRY: 4,
  /*
   * Member hub localstorage keys to be preserved
   */
  MEMBERHUB_KEYS_TO_BE_PRESERVED: ['auth_user', 'token'],
  /*
   * Available vertical's slugs
   */
  VERTICAL_SLUGS: {
    HEALTH_INSURANCE: 'hic',
    HEALTH_INSURANCE_OVERSEAS: 'hicovs',
    LIFE_INSURANCE: 'lic',
    ENERGY: 'cc-eng',
    ENERGY_LEGACY: 'eng',
    HOME_LOANS: 'hla',
    PERSONAL_LOANS: 'pla',
    CAR_INSURANCE: 'carinsurance',
    CAR_LOANS: 'cla',
    CREDIT_CARDS: 'ccc'
  },

  /*
  SessionStorage keys to be preserved
  */
  SESSIONSTORAGE_KEYS_TO_BE_PRESERVED: ['utms'],
  GLOBAL_FORMS: {
    REGISTER: 1,
    LOGIN: 2
  },
  /*
   * Vertical ID(s)
   */
  VERTICAL_ID: {
    HEALTH_INSURANCE: 1,
    HOME_LOANS: 2,
    LIFE_INSURANCE: 3,
    ENERGY: 4,
    CAR_LOANS: 5,
    PERSONAL_LOANS: 6,
    CAR_INSURANCE: 7,
    CREDIT_CARDS: 8,
    GENERIC: 9
  },
  /*
   * Deal alert(s) status label(s)
   */
  DEAL_ALERT_STATUS_LABEL: {
    0: null,
    1: 'ALERT SET',
    2: 'ALERT FOUND',
    3: 'COVERED'
  },
  /*
   * Support email for memberhub
   */
  CONTACT_EMAIL: 'myaccount@compareclub.com.au',
  /*
   * One tap prompt will be enabled in these routes
   */
  ONE_TAP_ENABLED_ROUTES: [
    '/',
    '/login/',
    '/register/',
    '/forgot-password/',
    '/health-insurance/',
    '/life-insurance/',
    '/car-insurance/',
    '/home-loans/',
    '/car-loans/',
    '/personal-loans/',
    '/energy/',
    '/expert-analysis/',
    '/life-insurance/over-50s-review/',
    '/health-insurance/dental-cover/',
    '/credit-cards/frequent-flyer-cards/',
    '/health-insurance/seniors/',
    '/health-insurance/singles/',
    '/energy/top-10-companies/',
    '/about-us/',
    '/health-insurance/ambulance-cover/',
    '/health-insurance/companies/',
    '/credit-cards/balance-transfer-cards/',
    '/careers/',
    '/life-insurance/funeral-cover/',
    '/health-insurance/couples/',
    '/life-insurance/over-50s/',
    '/credit-cards/'
  ],
  /*
   * One tap prompt will be enabled in pages which contains these route parts
   */
  ONE_TAP_ENABLED_ROUTE_SET: [
    '/expert-analysis/'
  ],

  /*
   * Auth related routes
   */
  AUTH_ROUTES: ['/login/', '/register/', '/forgot-password/', '/reset-password/'],

  /*
   * Password meter constants
   */
  PASSWORD_METER: {
    POOR: 1,
    WEAK: 2,
    GOOD: 3,
    STRONG: 4
  },
  PASSWORD_METER_REQUIRED_KEYS: {
    MIN_LENGTH: 1,
    UPPERCASE: 2,
    LOWERCASE: 3,
    SPECIAL_CHAR: 4,
    NUMBER: 5
  },

  /*
   * Gift card constants
   */
  GIFT_CARD_STATUS: {
    PENDING: 1,
    AVAILABLE: 2,
    ACTIVATED: 3,
    ARCHIVED: 4,
    INELIGIBLE: 5,
    EXPIRED: 6
  },
  ENERGY_PRODUCT_TYPE: {
    1: 'ELECTRICITY',
    2: 'GAS'
  },
  ENERGY_PRODUCT_TYPE_FLAG: {
    ELECTRICITY: 1,
    GAS: 2
  },
  GENERIC_PRODUCT_TYPE: {
    1: 'BROADBAND',
    2: 'SIM'
  },
  GENERIC_PRODUCT_TYPE_FLAG: {
    BROADBAND: 1,
    SIM: 2
  },
  /*
   * Vault funnel support constants
   */
  PAYMENT_FREQUENCY: {
    HEALTH_INSURANCE: [
      'Weekly',
      'Fortnightly',
      'Monthly',
      'Quarterly',
      'Annually'
    ],
    ENERGY: [
      'Monthly',
      'Bi-Monthly',
      'Quarterly',
      'Annually'
    ],
    LIFE: [
      'Fortnightly',
      'Monthly',
      'Quarterly',
      'Half-yearly',
      'Annually'
    ],
    GENERIC: [
      'Weekly',
      'Fortnightly',
      'Monthly',
      'Quarterly',
      'Annually'
    ],
    HOME_LOAN: [
      'Weekly',
      'Fortnightly',
      'Monthly'
    ]
  },
  PAYMENT_BILL_AMOUNTS: {
    ENERGY: {
      Monthly: [
        '$0-$99',
        '$100-$149',
        '$150-$199',
        '$200-$249',
        '$250-$299',
        '$300+'
      ],
      'Bi-Monthly': [
        '$0-$199',
        '$200-$299',
        '$300-$399',
        '$400-$499',
        '$500-$599',
        '$600+'
      ],
      Quarterly: [
        '$0-$299',
        '$300-$449',
        '$450-$599',
        '$600-$749',
        '$750-$899',
        '$900+'
      ],
      Annually: [
        '$0-$1,199',
        '$1,200-$1,799',
        '$1,800-$2,399',
        '$2,400-$2,999',
        '$3,000-$3,599',
        '$3,600+'
      ]
    }
  },
  COVER_TYPES: {
    HEALTH_INSURANCE: [
      {
        label: 'Hospitals + Extras',
        value: 'Combined'
      },
      {
        label: 'Hospital only',
        value: 'Hospital'
      },
      {
        label: 'Extras only',
        value: 'GeneralHealth'
      }
    ]
  },
  HOSPITAL_COVER_LEVEL: [
    {
      label: 'Basic',
      value: ['Basic','BasicPlus']
    },
    {
      label: 'Silver',
      value: ['Silver','SilverPlus']
    },
    {
      label: 'Bronze',
      value: ['Bronze','BronzePlus']
    },
    {
      label: 'Gold',
      value: ['Gold']
    },
    {
      label: 'All',
      value: ''
    }
  ],
  EXCESS: [
    {
      label: '$750',
      value: '750'
    },
    {
      label: '$500',
      value: '500'
    },
    {
      label: '$250',
      value: '250'
    },
    {
      label: '$0',
      value: '0'
    },
    {
      label: 'All',
      value: ''
    }
  ],
  STATE_OPTIONS: [
    {
      label: 'New South Wales',
      value: 'NSW',
      postcodeRanges: [
        {
          from: 1000,
          to: 1999
        },
        {
          from: 2000,
          to: 2599
        },
        {
          from: 2619,
          to: 2899
        },
        {
          from: 2921,
          to: 2999
        }
      ]
    },
    {
      label: 'Victoria',
      value: 'VIC',
      postcodeRanges: [
        {
          from: 3000,
          to: 3999
        },
        {
          from: 8000,
          to: 8999
        }
      ]
    },
    {
      label: 'Queensland',
      value: 'QLD',
      postcodeRanges: [
        {
          from: 4000,
          to: 4999
        },
        {
          from: 9000,
          to: 9999
        }
      ]
    },
    {
      label: 'South Australia',
      value: 'SA',
      postcodeRanges: [
        {
          from: 5000,
          to: 5999
        }
      ]
    },
    {
      label: 'Western Australia',
      value: 'WA',
      postcodeRanges: [
        {
          from: 6000,
          to: 6797
        },
        {
          from: 6800,
          to: 6999
        }
      ]
    },
    {
      label: 'Tasmania',
      value: 'TAS',
      postcodeRanges: [
        {
          from: 7000,
          to: 7999
        }
      ]
    },
    {
      label: 'Northern Territory',
      value: 'NT',
      postcodeRanges: [
        {
          from: 800,
          to: 999
        }
      ]
    },
    {
      label: 'Australian Capital Territory',
      value: 'ACT',
      postcodeRanges: [
        {
          from: 200,
          to: 299
        },
        {
          from: 2600,
          to: 2618
        },
        {
          from: 2900,
          to: 2920
        }
      ]
    }
  ],
  INLINE_EDIT_FIELDS: [
    {
      label: 'Reference #',
      formLabel: 'Reference #',
      value: 'reference'
    },
    {
      label: 'Next payment',
      formLabel: 'Next payment date',
      value: 'next-payment-date'
    },
    {
      label: 'Renewal date',
      formLabel: 'Renewal date',
      value: 'renewal'
    },
    {
      label: 'Comments',
      formLabel: 'Comments',
      value: 'comments'
    }
  ],
  ACCOUNT_TYPE: [
    {
      label: 'Home',
      value: 'Residential',
      name: 'residential'
    },
    {
      label: 'Business',
      value: 'SME',
      name: 'sme'
    }
  ],
  LIFE_INSURANCE_PAYMENT_METHODS: [
    { name: 'Through my Super fund', label: 'Through my Super fund', value: 'Through my Super fund' },
    { name: 'Out of pocket', label: 'Out of pocket', value: 'Out of pocket' },
    { name: 'Combination', label: 'Combination', value: 'Combination' }
  ],
  HOME_LOAN_TYPES:[
    { name: 'Fixed', label: 'Fixed', value: 'Fixed' },
    { name: 'Variable', label: 'Variable', value: 'Variable' },
    { name: 'Split (Combined)', label: 'Split (Combined)', value: 'Split (Combined)' }
  ],
  HOME_LOAN_FIXED_TERM_OPTIONS:[
    { name: '1 Year', label: '1 Year', value: '1 Year' },
    { name: '2 Years', label: '2 Years', value: '2 Years' },
    { name: '3 Years', label: '3 Years', value: '3 Years' },
    { name: '4 Years', label: '4 Years', value: '4 Years' },
    { name: '5 Years', label: '5 Years', value: '5 Years' },
    { name: 'Other', label: 'Other', value: 'Other' }
  ],
  HOME_LOAN_TERM_OPTIONS:[
    { name: '10 year', label: '10 year', value: '10 year' },
    { name: '15 year', label: '15 year', value: '15 year' },
    { name: '20 year', label: '20 year', value: '20 year' },
    { name: '25 year', label: '25 year', value: '25 year' },
    { name: '30 year', label: '30 year', value: '30 year' },
    { name: 'Other', label: 'Other', value: 'Other' }
  ],
  HOME_LOAN_PAYMENT_TYPES:[
    { name: 'Principal and Interest', label: 'Principal and Interest', value: 'Principal and Interest' },
    { name: 'Interest Only', label: 'Interest Only', value: 'Interest Only' }
  ],
  HOME_LOAN_PROPERTY_TYPES:[
    { name: 'Owner Occupier', label: 'Owner Occupier', value: 'Owner Occupier' },
    { name: 'Investment Property', label: 'Investment Property', value: 'Investment Property' },
    { name: 'Both', label: 'Both', value: 'Both' }
  ],
  GENERIC_CATEGORY_OPTIONS: [
    'Car insurance',
    'Electricity',
    'Gas',
    'Health insurance',
    'Home & content insurance',
    'Home loan',
    'Internet',
    'Life insurance',
    'Loan',
    'Pet Insurance',
    'Phone',
    'Solar',
    'Subscription',
    'Other'
  ]
}
