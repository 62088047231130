import { default as _91slug_938Zu6v33sYCMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as indexpMIotdOAfdMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guides6zFKWzchuWMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_93uQIcsEzyCHMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as index0nrMsRT1MHMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbacksTuAb3wgRWMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45uscwQTL6aUtCMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callbackwZeBNd6bQ5Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as merge6O7LLmO8e0Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as comprehensive84Ekijb2RUMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as indexlXoczSFtftMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93CmaPM6SvxVMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsiOP48GnDj8Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as results7E2eBsUoFfMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as index7Tx5Fpue9DMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexsLBLOn0ZTYMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticePJEZhuh8wGMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintspl882V09ZIMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usQVG2Vqvz4UMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93kXcWG8BglaMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as index8ezLs3TAefMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93Tk9cabDDFHMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93QmujibCgoxMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingsrnWwsPsWO3Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as index7NmGol3RxbMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as indexNjhvAYtraGMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as index40353ZCTjEMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexumawRQ9l6lMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexT6Yi7mEirMMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45detailsVSTMgkWph7Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue?macro=true";
import { default as completion8N6gVVUtaTMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue?macro=true";
import { default as details2LO6yZ1eJvMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue?macro=true";
import { default as _91step_93tNeKOffg4uMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93mBWOmsFj8CMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue?macro=true";
import { default as _91step_935XmVi9p1G3Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_93JEytIZBkvQMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_93QIYuHCbJjMMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45detailsEXqEwg3oyzMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue?macro=true";
import { default as rebate9FFzpOE88TMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue?macro=true";
import { default as digital_45eictsLhKObnUNMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as errorCXXKfOJsdiMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as thank_45youWq9iprSrBsMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as indexDpYLQ77EvdMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93EBAUchDHsIMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsJxWkm5EunzMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45you6PRkn7Wl8sMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93K7TGZsvEaYMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexgcJ4IgFbopMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_9387qOKypPWWMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexvjx7PsQUCTMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45passwordIVX2Ahz8kXMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as _91step_93FQmAeuOr7cMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expireduDeez276CYMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexyAoH6d7l41Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexGxppu9vhPyMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as error61AyOPv0JIMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as index9Q4cRvpdzPMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bt8pTnG4FMhMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chatHti1br1h3OMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexzaeftRhyEAMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as add1d0uL3DHCfMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupaMDbY75sudMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45saleSWbIe9tQ9ZMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as index3JVa9T4nhvMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93mluvXyasMHMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as detailsNzUkpvpSlaMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as indexXI2ipoCof0Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45you2HXNo43QUMMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexcwE5RQ2dIIMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexXckWG2cEcrMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as indexVb6MbtmQNkMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexEmdnaGZIAIMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_930SXEqQbQ40Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93OlbJLs25aQMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationVyZVh1jTVFMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsyMjnnRSVlnMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexRKnZ3yhE1OMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultseLMDRrGOZ8Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youg423Rdn9USMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_93S3QQR7GUpiMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as index9wtcrqJbVfMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexrpHWvjdh0eMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45ratesvtn0UYOfMQMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_933JPx3wdXU7Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsrinm1rvYBjMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youMn9FAeIfifMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as index1WDdUrafAGMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/index.vue?macro=true";
import { default as confirmationT4DHZOLLZRMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsIZN9aXjT74Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexYLHCgvLAS7Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as index3mpQn2yIlSMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as addy0mGjayJICMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youJlOP7k494dMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexEGj3dqLAwyMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successyldD3uuuekMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_93D4KesCIxaDMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailsD2OunIWVRxMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as login7TAoiGomUFMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93uRfm92UNJnMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93N16nltEkmnMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexLnsd0llSm3Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipszS3VLoipeNMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexByQswWYExKMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as post_45sale_45thank_45youB6SPi0g2SJMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_93G8wzKans4KMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93K3j5TnxsGIMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93hEOdMenFUUMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policy9GJPgMIeDQMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as register2AjLRAuYlwMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordQOcAEHqO7pMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as terms_45of_45useJIdfNVMfT8Meta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verify7xjUCzxnRlMeta } from "/codebuild/output/src3089333710/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbacksTuAb3wgRWMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93kXcWG8BglaMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsrnWwsPsWO3Meta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index7NmGol3RxbMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexNjhvAYtraGMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: index40353ZCTjEMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexumawRQ9l6lMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    meta: indexT6Yi7mEirMMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product?/:vertical()/additional-details",
    meta: additional_45detailsVSTMgkWph7Meta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product?/:vertical()/completion",
    meta: completion8N6gVVUtaTMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product?/details",
    meta: details2LO6yZ1eJvMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product?/form/energy/step/:step()",
    meta: _91step_93tNeKOffg4uMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product?/form/generic/step/:step()",
    meta: _91step_93mBWOmsFj8CMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product?/form/health-insurance/step/:step()",
    meta: _91step_935XmVi9p1G3Meta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product?/form/home-loan/step/:step()",
    meta: _91step_93JEytIZBkvQMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product?/form/life-insurance/step/:step()",
    meta: _91step_93QIYuHCbJjMMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product?/lic-details",
    meta: lic_45detailsEXqEwg3oyzMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product?/rebate",
    meta: rebate9FFzpOE88TMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/dashboard/vault/[[product]]/rebate.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordIVX2Ahz8kXMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login7TAoiGomUFMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: register2AjLRAuYlwMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordQOcAEHqO7pMeta || {},
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src3089333710/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]