<template>
  <NuxtLoadingIndicator />
  <NuxtLayout
    id="app-main"
    class="h-full"
  >
    <AtomsLoadingAnimation v-if="pageLoading" />

    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
import { storeToRefs } from 'pinia'

const layoutsStore = useLayoutsStore()
const utmsStore = useUtmsStore()
const userStore = useUserStore()

const { $tracking, $bus } = useNuxtApp()
const { devices } = deviceChecker()

const $router = useRouter()
const $route = useRoute()

const showMegaNav = computed(() => layoutsStore.navigation.showMegaNav)

/*
 * Page loading status property
 * @type {ComputedRef<*>}
 */
const pageLoading = computed(() => layoutsStore.getPageLoaderStatus)

const html = import.meta.client && document.documentElement
const body = import.meta.client && document.querySelector('body')

// Initialize optimizely
const { $optimizelyClient } = useNuxtApp()

watch(showMegaNav, async val => {
  await nextTick()

  if (devices.isDesktop.value) {
    return
  }

  if (val) {
    html.style.overflow = 'hidden'
    body.style.overflow = 'hidden'
  } else {
    html.style.overflow = ''
    body.style.overflow = ''
  }
})

watch(devices.width, async () => {
  await nextTick()

  if (devices.isDesktop.value) {
    if (html.style.overflow === 'hidden') {
      html.style.overflow = ''
    }

    if (body.style.overflow === 'hidden') {
      body.style.overflow = ''
    }
  }
})

/**
 * check optimizely & hotjar on every route change
 */
watch(() => $route.fullPath, async () => {
  utmsStore.GENERATE_UTMS($route.query)

  if (import.meta.client) {
    await $optimizelyClient.init()
  }
}, {
  immediate: true,
  deep: true
})

watch(() => $route.fullPath, () => {
  $tracking.pageview(additionalParams.value)
})

// Trustpilot
const trustpilotStore = useTrustpilotStore()
const { trustpilotData } = storeToRefs(trustpilotStore)

// Fetch the trustpilot data
const trustpilotResponse = useFetch('/api/trustpilot/data')
trustpilotData.value = trustpilotResponse?.data
// trustpilotStore.fetchTrustpilotData() // TODO: Remove

const additionalParams = ref({})

$bus.on('pageview', payload => {
  additionalParams.value = payload
})

/*
 * Global redirection handler
 */
$bus.off('redirection')
$bus.on('redirection', payload => {
  if (payload.path && (payload.path.includes('http'))) {
    window.location = payload.path
  } else {
    $router.push(payload)
  }
})

function getScrollPosition () {
  const offset = window.pageYOffset || html.scrollTop

  layoutsStore.scrollPosition = offset
}

onMounted(() => {
  $tracking.pageview(additionalParams.value)

  window.addEventListener('scroll', getScrollPosition)

  userStore.$patch(state => {
    state.userProfile = getLocal('auth_user')
      ? JSON.parse(getLocal('auth_user'))
      : {}
    state.token = getLocal('token')
      ? JSON.parse(getLocal('token'))
      : {}
  })

  getGoogleAnalyticsClientId()
})

onUnmounted(() => window.addEventListener('scroll', getScrollPosition))

async function getGoogleAnalyticsClientId () {
  while (!window.ga) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise(resolve => requestAnimationFrame(resolve))
  }

  await wait(500)

  const clientId = window.ga.getAll().length > 0
    ? window.ga.getAll()[0].get('clientId')
    : ''

  utmsStore.utms.ga_client_id = clientId
}
</script>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
.layout-enter-active,
.layout-leave-active {
  transition: all 0.2s;
}
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
.form-page-enter-active {
  transition: all 0.3s ease-out;
}
.form-page-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.form-page-enter-from,
.form-page-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

/* Braze Content card slider - override styles */
.ab-feed-buttons-wrapper {
  background-color: #193560 !important;
}
.ab-feed {
  background-color: #f9f9f9 !important;
  font-family: "Nunito Sans", Arial, sans-serif !important;
  .ab-card {
    border-radius: 10px !important;
    .ab-title {
      font-family: "Nunito Sans", Arial, sans-serif !important;
    }
    .ab-url-area {
      text-decoration: underline !important;
      font-size: 13px !important;
      font-weight: 700 !important;
      font-family: "Nunito Sans", Arial, sans-serif !important;
    }
  }
}
</style>